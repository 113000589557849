<script setup lang="ts">
</script>
<template>
  <!-- <RouterView /> -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<style>
  /* 120份 */
  /* html{
    font-size: 16px;
  }
  @media (max-width: 1920px) {
    html{
      font-size: 16px;
    }
  }
  @media (max-width: 1550px) {
    html{
      font-size: 12.91px;
    }
  }
  @media (max-width: 1280px) {
    html{
      font-size: 10.66px;
    }
  }
  @media (max-width: 1180px) {
    html{
      font-size: 9.83px;
    }
  }
  @media (max-width: 992px) {
    html{
      font-size: 8.26px;
    }
  }
  @media (max-width: 768px) {
    html{
      font-size: 6.4px;
    }
  }
  @media (max-width: 620px) {
    html{
      font-size: 5.16px;
    }
  } */

    /* 64份 */
  html{
    font-size: 30px;
  }
  @media (max-width: 1920px) {
    html{
      font-size: 30px;
    }
  }
  @media (max-width: 1550px) {
    html{
      font-size: 24.21px;
    }
  }
  @media (max-width: 1280px) {
    html{
      font-size: 20px;
    }
  }
  @media (max-width: 1180px) {
    html{
      font-size: 18.43px;
    }
  }
  @media (max-width: 992px) {
    html{
      font-size: 15.5px;
    }
  }
  @media (max-width: 768px) {
    html{
      font-size: 12px;
    }
  }
  @media (max-width: 620px) {
    html{
      font-size: 9.68px;
    }
  }
</style>
